/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/anhnmt/library-graveyard@main/shortcode.min.js
 * - /gh/anhnmt/library-graveyard@main/jquery.themepunch.tools.min.js
 * - /gh/anhnmt/library-graveyard@main/jquery.themepunch.revolution.js
 * - /gh/anhnmt/library-graveyard@main/revolution-plugin.min.js
 * - /gh/anhnmt/library-graveyard@main/rev-script-4.min.js
 * - /gh/anhnmt/library-graveyard@master/custom.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
